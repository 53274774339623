<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Camera Response Functions:<br>
                Database (DoRF) and Model (EMOR)</font></p>
              </div></td>
          </tr>
          <tr>
            <td><p align="left">The camera response function relates image irradiance at the image plane to the measured intensity values. Applications such as color constancy, photometric stereo, and shape from shading, require scene radiance rather than image intensity. The camera response must be recovered in order to obtain image irradiance or ultimately scene radiance, from an image. The response can be recovered from an image of a chart of known reflectance, such as a MacBeth chart, or from multiple images of the same scene taken at different exposures. In either case a parameterized model of response is important. Models of response can be evaluated using the Database of Response (DoRF). Response functions may be recovered using our Empirical Model of Response (EMoR).  </p>
            </td>
          </tr>
          <tr>
            <td><div align="center">
              <table width="100%" height="598" border="0" align="left">
                <tbody><tr>
                  <td><div align="center" class="style1"> 
                    <div align="left" class="style2">Database of Response Functions (DoRF)</div>
                  </div>
                  <blockquote>
                        <p>To evaluate response models, we collected 201 response curves for common brands of films, ccds, as well as video, and digital cameras. The data was obtained from charts or was measured. All of the curves in the database are monotonic and normalized to have domain and range [0 , 1] and sampled at &nbsp;1000 points.&nbsp;The database is in the form of a text file. For each entry we give the name of the curve. The curves are linear in irradiance and intensity, however, the scale on which the curves were originally obtained, eg. log-log, is also specified. The irradiance entries are listed below the heading "I=," while the <font face="Arial, Helvetica, sans-serif">intensity</font> is listed under "B=." The curves may be downloaded at [<a href="https://cave.cs.columbia.edu/old/software/dorf/response/dorfCurves.zip">DoRF curves</a>].<br>
                          <br>
                        </p>
                    </blockquote></td>
                </tr>
                <tr>
                  <td><div align="center" class="style1"> 
                    <div align="left" class="style2">Empirical Model of Response (EMoR)</div>
                  </div>
                  <blockquote>
                        <p>Our model provides a means of obtaining a response from a chart. A chart of known reflectance, such as the MacBeth chart, has patches of known reflectance. Scene radiance values are therefore known on patches up to a scale factor. Thus, an image of the chart provides a limited set of intensity/radiance pairs. Using our model, the response function can be found by interpolation. Alternately, multiple images of the same scene taken at different exposures permit recovery of the response using our model. This is outlined in paper listed below. We provide the model in the text file <a href="https://cave.cs.columbia.edu/old/software/dorf/response/emor.txt">emor.txt</a>. This file lists the mean response "f0" along with the first 25 principle components of PCA applied to DoRF, labeled h(1)-h(25). The intensity samples are the same as in the DoRF database and are listed under "I=." The text file <a href="https://cave.cs.columbia.edu/old/software/dorf/response/invemor.txt">invemor.txt</a> is our model of the inverse response.
                          <br>
                        </p>
                    </blockquote></td>
                </tr>
                <tr>
                  <td><span class="style2">For more information see: </span>
<blockquote>
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Modeling the Space of Camera Response Functions,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">M.D. Grossberg and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">IEEE Transactions on Pattern Analysis and Machine Intelligence,<br data-v-0ff51178=""></span><span data-v-0ff51178="">Vol. 26, No. 10, pp. 1272-1282, Oct. 2004<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Grossberg_PAMI04.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=182" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Computational+Imaging&amp;pid=Radiometric+Camera+Calibration" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
</blockquote>
                    </td></tr>
              </tbody></table>
            </div>
              <div align="left"></div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>